import React, { useContext } from 'react';
import { AuthContext } from './AuthContext';

const Login = () => {
  const { login } = useContext(AuthContext);

  const handleLogin = () => {
    // Здесь можно добавить логику для аутентификации
    // Для простоты примера, мы просто логинимся
    login({ name: 'John Doe', email: 'john@example.com' });
  };

  return (
    <button onClick={handleLogin}>Войти</button>
  );
};

export default Login;