import React, { useContext } from 'react';
import { AuthContext } from './AuthContext';

const Logout = () => {
  const { logout, isLoggedIn } = useContext(AuthContext);

  const handleLogout = () => {
    logout();
  };

  return (
    <button onClick={handleLogout} disabled={!isLoggedIn}>Выйти</button>
  );
};

export default Logout;