import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider, AuthContext } from './AuthContext';
import Login from './Login';
import Logout from './Logout';
import ProtectedRoute from './ProtectedRoute';

const App = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <div style={{ fontFamily: 'Arial, sans-serif' }}>
          <header style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem', borderBottom: '1px solid #000' }}>
            <h1>Lious</h1>
            <nav>
              <button href="/register">О нас</button>
              <button>Почему стоить выбрать нас?</button>
              <Login />
              <Logout />
            </nav>
          </header>
          <main style={{ display: 'flex', justifyContent: 'space-around', padding: '1rem' }}>
            <Routes>
              <Route path="/protected" element={
                <ProtectedRoute>
                  <section>
                    <h2>Защищенный контент</h2>
                    <p>Этот контент доступен только авторизованным пользователям.</p>
                  </section>
                </ProtectedRoute>
              } />
              <Route path="/" element={
                <div>
                  <section>
                    <h2>Linux для начинающих</h2>
                    <button>Записаться</button>
                    <div style={{ border: '1px solid #000', padding: '1rem', marginTop: '1rem' }}>
                      <img src="linux-for-beginners.png" alt="Linux for Beginners" style={{ maxWidth: '100%', height: 'auto' }} />
                    </div>
                  </section>
                  <section>
                    <h2>Linux для продвинутых</h2>
                    <button>Записаться</button>
                    <div style={{ border: '1px solid #000', padding: '1rem', marginTop: '1rem' }}>
                      <img src="linux-for-beginners.png" alt="Linux for Beginners" style={{ maxWidth: '100%', height: 'auto' }} />
                    </div>
                  </section>
                  <section>
                    <h2>Linux траблшутинг</h2>
                    <button>Записаться</button>
                    <div style={{ border: '1px solid #000', padding: '1rem', marginTop: '1rem' }}>
                      <img src="linux-for-beginners.png" alt="Linux for Beginners" style={{ maxWidth: '100%', height: 'auto' }} />
                    </div>
                  </section>
                </div>
              } />
            </Routes>
          </main>
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;